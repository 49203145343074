<template>

<v-row no-gutters>
<v-col xs="12" sm="12" md="12" lg="9" xl="7">
    
<v-row class="ml-2 mb-4" justify="space-between"  no-gutters>
      <span class="font-weight-bold text-h5 text--secondary" >
        Telehealth Visits
      </span>
      
    </v-row>

    <v-btn depressed class="mb-5" @click="createNewNote()" large rounded >

    <v-icon left color="primary"  >mdi-video</v-icon>
    Create Telehealth Room
 
</v-btn>


    <v-row class="ml-2 mb-4" justify="space-between"  no-gutters>
      <span class="font-weight-bold text-subtitle-1 text--secondary" >
        Quick Access Recordings
      </span>
      
    </v-row>

    <v-row no-gutters>
        <v-card v-for="(file, index) in quickAccessList" :key="index"  color="transparent" flat>
            <v-row no-gutters>
                <v-card flat outlined style="overflow:hidden"   class="mr-2 mb-4" width="230">
            <v-card height="142" flat color="grey darken-4" tile>
                <v-row justify="center" align="center" class="fill-height">
                    <v-icon class="ma-5" size="60" dark>mdi-play</v-icon>
                </v-row>
            </v-card>
            
          <v-row no-gutters>
              <v-card flat dark class="flex" tile color="grey darken-3">
                  <v-list-item>
                  <v-list-item-content>
                      <v-list-item-title>
                          Recording
                      </v-list-item-title>
                      <v-list-item-subtitle>
                          {{file.author}}
                      </v-list-item-subtitle>
                      <v-list-item-subtitle>
                          {{relativeTime(file.date)}}
                      </v-list-item-subtitle>
                  </v-list-item-content>
              </v-list-item>
              </v-card>
              
          </v-row>
        </v-card>
        <v-card flat outlined style="overflow:hidden"   class="mr-2 mb-4" width="230">
            <v-skeleton-loader
            class="elevation-0"
            type="article"
          ></v-skeleton-loader>
          <v-row no-gutters>
              <v-card flat class="flex" tile color="grey lighten-3">
                  <v-list-item>
                  <v-list-item-content>
                      <v-list-item-title>
                          Transcription
                      </v-list-item-title>
                      <v-list-item-subtitle>
                          {{file.author}}
                      </v-list-item-subtitle>
                      <v-list-item-subtitle>
                          {{relativeTime(file.date)}}
                      </v-list-item-subtitle>
                  </v-list-item-content>
              </v-list-item>
              </v-card>
              
          </v-row>
        </v-card>
        <v-card color="transparent" flat class="pa-2">
            <div>
            Start Time: {{getConversationalDateAndTimeFromTimestamp(file.date)}}
            </div>
            <div>
            Duration: {{file.duration}}
            </div>
            <div>
            Participants: {{file.participants}}
            </div>
        </v-card>
            </v-row>
        </v-card>
    </v-row>

    <v-row class="ml-2 mb-4" justify="space-between"  no-gutters>
      <span class="font-weight-bold text-subtitle-1 text--secondary" >
        Telehealth Visit History
      </span>
      
    </v-row>

    <v-row no-gutters>
        <v-card v-for="(file, index) in datesList" :key="index"  color="transparent" flat>
            <v-row no-gutters>
                <v-card flat outlined style="overflow:hidden"   class="mr-2 mb-4" width="230">
            <v-card height="142" flat color="grey darken-4" tile>
                <v-row justify="center" align="center" class="fill-height">
                    <v-icon class="ma-5" size="60" dark>mdi-play</v-icon>
                </v-row>
            </v-card>
            
          <v-row no-gutters>
              <v-card flat dark class="flex" tile color="grey darken-3">
                  <v-list-item>
                  <v-list-item-content>
                      <v-list-item-title>
                          Recording
                      </v-list-item-title>
                      <v-list-item-subtitle>
                          {{file.author}}
                      </v-list-item-subtitle>
                      <v-list-item-subtitle>
                          {{relativeTime(file.date)}}
                      </v-list-item-subtitle>
                  </v-list-item-content>
              </v-list-item>
              </v-card>
              
          </v-row>
        </v-card>
        <v-card flat outlined style="overflow:hidden"   class="mr-2 mb-4" width="230">
            <v-skeleton-loader
            class="elevation-0"
            type="article"
          ></v-skeleton-loader>
          <v-row no-gutters>
              <v-card flat class="flex" tile color="grey lighten-3">
                  <v-list-item>
                  <v-list-item-content>
                      <v-list-item-title>
                          Transcription
                      </v-list-item-title>
                      <v-list-item-subtitle>
                          {{file.author}}
                      </v-list-item-subtitle>
                      <v-list-item-subtitle>
                          {{relativeTime(file.date)}}
                      </v-list-item-subtitle>
                  </v-list-item-content>
              </v-list-item>
              </v-card>
              
          </v-row>
        </v-card>
        <v-card color="transparent" flat class="pa-2">
            <div>
            Start Time: {{getConversationalDateAndTimeFromTimestamp(file.date)}}
            </div>
            <div>
            Duration: {{file.duration}}
            </div>
            <div>
            Participants: {{file.participants}}
            </div>
        </v-card>
            </v-row>
        </v-card>
    </v-row>

</v-col>
<v-col class="hidden-md-and-down px-1 pl-3" xs="0" sm="0" md="0" lg="3" xl="5">
<v-text-field style="position: sticky; top: 75px;"  placeholder="Search within recordings" flat full-width solo-inverted hide-details >
    <v-icon slot="prepend-inner" >mdi-magnify</v-icon>
      </v-text-field>
</v-col>
</v-row>
</template>

<script>
import HelperMixin from '@/core/mixins/HelperMixin'
import moment from "moment-timezone";
export default {
    mixins: [HelperMixin],
    methods: {
        getRndInteger(min, max) {
            console.log('random number')
            console.log(min)
            return Math.floor(Math.random() * (max - min) ) + min;
            },
            relativeTime(value) {
      let now = new Date().getTime()
      var Difference_In_Time = now - value;

      let days = Difference_In_Time / (3600000 * 24);
      
      // if tis less than three days ago use moment.
      if (value && Math.floor(days) < 6) {
        let dt = moment(value)
          .tz(moment.tz.guess())
          .format("MM/DD/YYYY h:mm a");
        if (dt.includes("12:00 am")) {
          return "Today";
        } else {
          return moment(value).tz(moment.tz.guess()).calendar();
        }
      } else if (value) {
        return this.getConversationalDateAndTimeFromTimestamp(value)
      }
      return "";
    },
    },
    computed: {
        namesOfPaperDocuments() {
            return ['Telehealth Transcription']
        },
        authors() {
            return ['M. Foote PA-C', 'M. Brodegard PA-C', 'Q Smith MD', 'C. Johns MD', 'S. Crunkleton MD', 'K. Jones MD', 'D Brodegard PA-C', 'William Davis MD']
        },
        namesOfImagingFiles() {
            return ['Cervical Spine w/o Contrast', 'Chest X-Ray', ]
        },
        datesOfRecords() {
            let dates = []
            for (let i = 0; i < 15; i++) {
                let date = new Date()
                date.setDate( date.getDate() - Math.floor(Math.random() * 100) )
                date.setHours(this.getRndInteger(8, 18), this.getRndInteger(0, 59), this.getRndInteger(0, 59), this.getRndInteger(0, 999))
                dates.push(date.getTime())
            }
            return dates

        },
        quickAccessList() {
            let quickAccessFiles = []

            for (let i = 0; i < 1; i++) {
                let randomAuthor = this.getRndInteger(0, this.authors.length - 1)
                console.log(randomAuthor)
                let title = this.namesOfPaperDocuments[this.getRndInteger(0, this.namesOfPaperDocuments.length - 1)]
                let author = this.authors[randomAuthor]
                let date = this.datesOfRecords[this.getRndInteger(0, this.datesOfRecords.length - 1)]
                let duration = this.getRndInteger(8, 38) + 'm, ' + this.getRndInteger(8, 18) + 's'
                let participants = this.getRndInteger(2, 4)
                let file = {title: title, author: author, date: date, duration: duration, participants: participants}
                console.log(file)
                quickAccessFiles.push(file)
            }
            
            return quickAccessFiles
        },
        datesList() {
            let quickAccessFiles = []

            for (let i = 0; i < 5; i++) {
                let randomAuthor = this.getRndInteger(0, this.authors.length - 1)
                console.log(randomAuthor)
                let title = this.namesOfPaperDocuments[this.getRndInteger(0, this.namesOfPaperDocuments.length - 1)]
                let author = this.authors[randomAuthor]
                let date = this.datesOfRecords[this.getRndInteger(0, this.datesOfRecords.length - 1)]
                let duration = this.getRndInteger(8, 38) + 'm, ' + this.getRndInteger(8, 18) + 's'
                let participants = this.getRndInteger(2, 4)
                let file = {title: title, author: author, date: date, duration: duration, participants: participants}
                console.log(file)
                quickAccessFiles.push(file)
            }
            
            return quickAccessFiles
        }
    }

}
</script>

<style>

</style>