<template>
<v-card tile flat color="black" width="100vw" style="height:90vh">
  <v-row no-gutters class="fill-height flex" justify="center" align="center">

    <video-room style="width:100vh" :expandToFillScreen="true" v-if="accessToken" @portraitOrientation="landscape = false" @landscapeOrientation="landscape = true"  @roomClosed="handleClose()" @roomConnected="creatingRoom = false" :roomToken="accessToken"/>
  </v-row>
</v-card>
</template>

<script>
import VideoRoom from '../components/videoroom/VideoRoom.vue'

export default {
  components: { VideoRoom },
data() {
    return {
      miniView: true,
      landscape: false,
      creatingRoom: false,
      accessToken: null
    }
  },
  mounted() {

this.accessToken = this.$route.params.accessToken


    //http://localhost:8080/video/eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiIsImN0eSI6InR3aWxpby1mcGE7dj0xIn0.eyJqdGkiOiJTSzk2MWIzZDY4Y2FiODI0ZWU5ZWQwN2E5NTEyZTcwZmY1LTE2MTU2NzYwNzgiLCJncmFudHMiOnsidmlkZW8iOnsicm9vbSI6IlJNY2QwNjMxNjQxOWRjYTU2YjE1MjVlNDMyODcyOTA3ZGIifSwiaWRlbnRpdHkiOiJJbnZpdGVlIn0sImlzcyI6IlNLOTYxYjNkNjhjYWI4MjRlZTllZDA3YTk1MTJlNzBmZjUiLCJleHAiOjE2MTU2Nzk2NzgsIm5iZiI6MTYxNTY3NjA3OCwic3ViIjoiQUNiMGM3NDZiM2QzOGQ5YWI5ZGY1ZjdlODM5ZTkwODk2ZSJ9.UVVY7Ewicxjv2YDgwLFxxQe60Uy68Rdz_Yd4LQykxKg


    window.addEventListener("beforeunload", () => {
      this.room.disconnect();
    });
  }
}
</script>

<style>

</style>